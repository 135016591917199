import React from "react";
import { useForm } from "react-hook-form";

import { supabase } from "../helpers/supabase";
import { RingLoader } from "react-spinners";

import "../styles/styles.scss";

import {
  loginContainerStyles,
  loginBoxStyles,
  loginFieldStyles,
} from "../styles/styles";
import { FaKey } from "react-icons/fa";
import { AuthProvider } from "../context/auth";
import { navigate } from "gatsby";
import Helmet from "react-helmet";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    if (data.password !== data.confirm_password) {
      alert("Passwords must match");
      return;
    }
    supabase.auth.update({ password: data.password }).then((response) => {
      if (response.error) {
        alert(response.error.message);
      } else {
        alert("Password successfully updated");
        navigate("/");
      }
    });
  };

  return (
    <div class="section" style={loginContainerStyles}>
      <Helmet title="Password Reset | Frontier Solutions" />
      <div class="columns is-centered">
        <div class="column is-4-desktop is-6-tablet">
          <form
            class="box"
            style={loginBoxStyles}
            onSubmit={handleSubmit(onSubmit)}
          >
            <p class="title has-text-centered">Reset password</p>
            <p class="is-size-7">
              Almost done. Please enter your new password below (must be at
              least 8 characters).
            </p>
            <div class="field" style={loginFieldStyles}>
              <label class="label">New Password</label>
              <div class="control has-icons-left">
                <input
                  id="password-input"
                  class="input"
                  type="password"
                  {...register("password", { required: true, minLength: 8 })}
                />
                <span class="icon is-small is-left">
                  <FaKey />
                </span>
              </div>
              {errors.password?.type === "minLength" && (
                <p class="has-text-danger is-size-7">
                  Password must be 8 characters
                </p>
              )}
            </div>
            <div class="field" style={loginFieldStyles}>
              <label class="label">Confirm New Password</label>
              <div class="control has-icons-left">
                <input
                  id="password-confirm-input"
                  class="input"
                  type="password"
                  {...register("confirm_password", {
                    required: true,
                    minLength: 8,
                  })}
                />
                <span class="icon is-small is-left">
                  <FaKey />
                </span>
                {errors.confirm_password?.type === "minLength" && (
                  <p class="has-text-danger is-size-7">
                    Password must be 8 characters
                  </p>
                )}
              </div>
            </div>
            <label>
              <input
                type="checkbox"
                onClick={() => {
                  // Switch password type
                  const passwordInput =
                    document.getElementById("password-input");
                  const passwordConfirmInput = document.getElementById(
                    "password-confirm-input"
                  );
                  if (passwordInput.type === "password") {
                    passwordInput.type = "text";
                    passwordConfirmInput.type = "text";
                  } else {
                    passwordInput.type = "password";
                    passwordConfirmInput.type = "password";
                  }
                }}
              />
              <span style={{ marginLeft: "5px" }}>Show password</span>
            </label>
            <div class="field" style={loginFieldStyles}>
              <button class="button is-fullwidth fpLoginButton" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  // }
};

export default ForgotPassword;
